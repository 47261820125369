import { LocationModule } from "@/store/location";
import {
  Customer,
  CustomerConfigData,
  CustomerData,
  FirmData,
} from "@planetadeleste/vue-mc-gw";
import { get, isEmpty } from "lodash";
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

@Component
export default class CustomerMixin extends Vue {
  @VModel({ type: Object, default: () => ({}) }) obCustomer!:
    | Customer
    | Partial<CustomerData>;

  @Prop(Boolean) readonly firmOnly!: boolean;

  get obFirm(): Partial<FirmData> {
    return this.getData("firm", {});
  }

  set obFirm(obData: Partial<FirmData>) {
    this.setData("firm", obData);
  }

  get obConfig() {
    return this.getData("config", {});
  }

  set obConfig(obData: Partial<CustomerConfigData>) {
    this.setData("config", obData);
  }

  get states() {
    return LocationModule.states;
  }

  get towns() {
    return LocationModule.towns;
  }

  getData(sKey: string, sDefault: any = null) {
    return this.obCustomer instanceof Customer
      ? this.obCustomer.get(sKey, sDefault)
      : get(this.obCustomer, sKey, sDefault);
  }

  setData(sKey: string, sValue: any, sync: boolean = false) {
    if (this.obCustomer instanceof Customer) {
      this.obCustomer.set(sKey, sValue);

      if (sync) {
        this.obCustomer.sync();
      }
    } else {
      this.$set(this.obCustomer, sKey, sValue);
    }
  }

  onSetLocation(iValue: number, sKey: string) {
    if (!iValue) {
      return;
    }

    if (sKey === "state") {
      const obState = this.states.find({ id: iValue });

      if (obState) {
        this.obFirm.dgi_loc_nom = obState.name;
      }
    } else if (sKey === "town") {
      const obTown = this.towns.find({ id: iValue });

      if (obTown) {
        this.obFirm.dgi_dpto_nom = obTown.name;
      }
    }
  }

  onCopyFirmData(sField: keyof FirmData) {
    const sValue = get(this.obFirm, sField);

    if (isEmpty(sValue)) {
      return;
    }

    switch (sField) {
      case "address":
        if (isEmpty(this.obFirm.dgi_dir_fiscal)) {
          this.$set(this.obFirm, "dgi_dir_fiscal", sValue);
        }
        break;
    }
  }

  onSave() {
    this.$emit("save");
  }
}
